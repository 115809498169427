@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: perspective(1px) translateY(-50%);
  -ms-transform: perspective(1px) translateY(-50%);
  transform: perspective(1px) translateY(-50%);
}

$grad-purble: #3a09c9;
$grad-blue: #1034c2;
$grad-pink: #792491;

body {
  min-width: 100vw;
  min-height: 100vh;
  background-color: #111;

  .bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: conic-gradient(at 100% 100%, $grad-pink 0deg, $grad-blue 260deg, $grad-purble 295deg, $grad-pink 360deg);
    filter: blur(48px);
    z-index: -100;
  }
}

.adult-content-post-placeholder {
  display: none;
}

#sidebar {
  height: 100%;
  flex: 0 0 auto;
  overflow: hidden;
  padding: 1rem $section-spacing 3rem $section-spacing;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .site-title {
    flex: 0 0 auto;
    font-family: $title-font-family;
    font-weight: normal;
    font-size: 18pt;
    color: rgba(255,255,255,.75);
    margin-top: 0;
    margin-bottom: 20px;

    a {
      color: inherit;
      &:hover { color: white; }
    }

    @media (min-width: $large-breakpoint) {
      text-align: left;
    }

    .back-arrow { margin-right: 0.5rem; }
  }

  .page-link, .tag-link {
    font-family: $title-font-family;

    .arrow {
      display: inline-block;
      margin-top: -0.75rem;
      vertical-align: middle;
    }
  }

  .copyright {
    font-size: 12pt;
    flex: 0 0 auto;
    color: $gray-4;
    padding-left: 0.35rem;
  }
}

main.container > .content {
  color: $body-color;
  padding: 1rem $section-spacing #{$section-spacing * 2} $section-spacing;
}

.page-title, .post-title {
  font-family: $title-font-family;
}

.tags-list {
  a {
    text-decoration: none;
    display: block;

    .tag-count {
      background: $link-color;
      color: rgba(255, 255, 255, 0.8);
      margin-left: 0.25em;
      border-radius: 1000px;
      font-size: 0.75em;
      padding-left: 0.6em;
      padding-right: 0.6em;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.home .content h2 {
  margin-top: 0;
}


/* -----------------------------------------------------------
  Mobile view
----------------------------------------------------------- */

// Hide secondary nav content in sidebar
// Hide lead paragraph in sidebar
#sidebar {
  header ~ *, header ~ nav, p.lead {
    display: none;
  }

  #sidebar-nav-links {
    width: 13rem;
    margin: 0 auto;
  }
}

// Make header elements blend into sidebar / background
main.container > header {
  background: transparent;
  color: white;
  margin: -4.5rem $section-spacing $section-spacing $section-spacing;

  @media (min-width: $large-breakpoint) {
    padding-top: 0;
  }

  .page-title, .post-title {
    margin-top: 1rem;
    margin-bottom: -1rem;
  }

  h1, h2 {
    color: white;
  }
}


/* -----------------------------------------------------------
  Mobile view for home page)
----------------------------------------------------------- */

.home #sidebar {

  // Sidebar content is centered on mobile
  text-align: center;

  // Show secondary nav content + lead
  header ~ *, p.lead {
    display: block;
  }

  header {
    ~ nav {
      display: flex;
    }
  }

  // Slightly more bottom padding to compensate for heading not match 100% of
  // line-height on top
  > *:last-child {
    margin-bottom: 0.5rem;
  }
}


/* -----------------------------------------------------------
  Tablet / Desktop view
----------------------------------------------------------- */

@media (min-width: $large-breakpoint) {
  body {
    min-height: 100vh;
    overflow-x: hidden;
  }

  /* Undo mobile CSS */

  #sidebar, .home #sidebar {
    width: 25%;
    text-align: unset;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  #sidebar {
    position: fixed;
    left: 0;
    top: 0;
    width: 25%;
    height: 100%;
    padding: 3rem;
    pointer-events: none;

    a {
      pointer-events: initial;
    }

    .site-title {
      a { color: white; }
      .back-arrow { display: none; }
    }

    p.lead, header ~ * {
      display: block;
    }

    header ~ nav {
      display: flex;
    }

    #sidebar-nav-links {
      width: auto;
      margin: 0;
    }
  }

  main.container {
    color: $body-color;
    padding: $section-spacing ($section-spacing * 2) $section-spacing 10%;
    height: 100vh;
    max-width: 1000px;
    margin: 0 auto;
    box-sizing: border-box;

    > header {
      color: inherit;
      margin: 0;
      padding-left: $section-spacing;

      h1, h2 {
        color: inherit;

        &:last-child {
          margin-bottom: 1rem;
        }
      }
    }

    > * {
      padding: 0;
      padding-top: 20px;
    }

    p:not(.refsource) {
      text-align: justify;
    }
  }
}


/* -----------------------------------------------------------
  Sidebar links + nav
----------------------------------------------------------- */

#sidebar a {
  color: #fff;

  svg {
    fill: rgba(255, 255, 255, 0.8);
  }

  img {
    width: 24px;
    height: 24px;
    opacity: 0.8;
    margin: 0;
  }

  &:hover, &:focus, &.active {
    svg { fill: #fff; }
    img { opacity: 1.0; }
  }

  &:hover, &:focus {
    text-decoration: underline;
    &.icon {
      text-decoration: none;
    }
  }

  &.active {
    font-weight: bold;
  }
}

#sidebar nav {
  display: flex;

  .adult-content {
    height: 24px;
  }
}

#sidebar-nav-links {
  font-size: 14pt;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  align-items: flex-start;
}

#sidebar-icon-links {
  max-width: 100%;
  flex-flow: row wrap;
  flex-direction: row;
  flex: 0 0 auto;
  margin-top: 1rem;
  justify-content: center;
  align-content: start;

  @media (min-width: $large-breakpoint) {
    justify-content: flex-start;
  }
}

#sidebar nav > * {
  display: inline-block;
  line-height: 1.75;
}

#sidebar nav > .icon {
  font-size: 1.5rem;
  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 0.1rem 0.25rem;
  vertical-align: bottom;
  line-height: 1;

  &.social {
    margin: 0.1em 0.25em;
    font-size: 1.25rem;

    img, svg {
      width: 24px;
      height: 24px;
    }
  }
}

#search-bar {
  pointer-events: all;
}

.warning-modal {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.9;
}

.warning-popup {
  position: fixed;
  z-index: 101;
  width: 30rem;
  height: 20rem;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #222;
  border-radius: 0.5rem;

  p {
    font-size: 14pt;
    padding: 2rem 2rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .container {
    display: block;
    width: 100%;
    text-align: center;
    height: 2.5rem;
    padding: 0;
    background: transparent;
  }

  a {
    display: inline-block;
    color: white;
    cursor: pointer;
    width: 15rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 0.5rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
    background: #293;
    text-align: center;
    padding: 0;

    &:hover { background: #3a4; }

    &.no {
      background: #932;
      &:hover { background: #a43; }
    }
  }
}
