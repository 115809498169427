// Posts and pages
//
// Each post is wrapped in `.post` and is used on default and post layouts. Each
// page is wrapped in `.page` and is only used on the page layout.

article + *, .post-body ~ section {
  padding-top: $section-spacing;
  border-top: 1px solid $border-color;
  margin-top: $section-spacing;

  > h2:first-child, > h3:first-child {
    margin-top: 0;
  }
}

.post, .page {
  .content {
    li + li {
      margin-top: .25rem;
    }

    video {
      width: 100%;
    }
  }
}

// Blog post or page title
.page-title,
.post-title,
.post-title a {
  color: $heading-color;
}
.page-title,
.post-title {
  margin-top: 0;
}

// h1-size h2
h2.post-title, h2.page-title {
  font-size: 2rem;
}

$post-meta-item-height: 20px;

// Meta data line below post title
.post-meta {
  margin-top: -.5rem;
  margin-bottom: 1rem;
  color: $body-muted;

  .post-date {
      vertical-align: middle;
      height: $post-meta-item-height;
      line-height: $post-meta-item-height;
  }

  .post-tags a {
    margin-left: 0.5rem;
    white-space: nowrap;
    text-decoration: none;

    .tag-name {
      font-family: $root-font-family;
      color: black;
      font-size: 12pt;
      font-weight: 400;
      text-decoration: none;
      padding: 0 0.25rem 0 0.5rem;
    }

    .tag-end {
      content: " ";
      width: 10px;
      clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
    }

    .tag-name, .tag-end {
      background: $blue-faint;
      mix-blend-mode: lighten;
      display: inline-block;
      vertical-align: middle;
      height: $post-meta-item-height;
      line-height: $post-meta-item-height;
    }

    &:hover {
      .tag-name, .tag-end { background: $blue; }
    }
  }
}

.posts-by-tag h2 {
  text-transform: capitalize;
}

.posts-list {
  padding-left: 0;
  list-style: none;

  h3 {
    margin-top: 0;
  }

  li {
    small {
      font-size: 75%;
      color: #999;
      white-space: nowrap;
    }

    a:hover {
      color: $link-color;
      text-decoration: none;

      small {
        color: inherit;
      }
    }
  }
}

#toc {
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;

  #toc-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li.level-2 {
      padding-left: 1rem;
    }

    li.level-3 {
      padding-left: 3rem;
    }

    li.level-4 {
      padding-left: 5rem;
    }
  }

  #toc-title {
    margin: 0 0 0.5rem 0;
  }
}
