/* -----------------------------------------------------------
  Image captions
----------------------------------------------------------- */

.caption {
  font-size: 0.8rem;
  color: $gray-4;
  margin-top: -1.5rem;
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;

  &.caption-flex {
    display: flex;

    span {
      flex: 1;
    }
  }
}


/* -----------------------------------------------------------
  Guide difficulty
----------------------------------------------------------- */

.difficulty {
  padding: 0.5rem;
  margin: 1rem;
  @media screen and (max-width: 1000px) {
    margin: 1rem 0;
  }
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: .25rem;
  text-align: center;

  div {
    color: $gray-6;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }

  span {
    display: inline-block;
    opacity: 0.25;
    min-width: 2rem;
    min-height: 2rem;
    vertical-align: top;
    padding: 0.5rem 1.25rem;
    @media screen and (max-width: 1000px) {
      padding: 0.25rem 0.75rem;
    }
    box-sizing: border-box;
    color: white;

    &.active { opacity: 1.0; }
  }

  .beginner { background: #3a2; }
  .intermediate { background: #a87000; }
  .advanced { background: #b42; }
}


/* -----------------------------------------------------------
  Image viewer
----------------------------------------------------------- */

img.zoomable, video.zoomable {
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
}

#popup-dimmer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
}

#popup-image, #popup-video {
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 80%;
  max-height: 80%;
  @include vertical-align();
}


/* -----------------------------------------------------------
  Citations
----------------------------------------------------------- */

.citation {
  font-size: 70%;
  display: inline-block;
  vertical-align: top;
  font-weight: 400;
}

section.references {
  text-align: left;
  font-size: 80%;
}

div.refsource {
  margin-bottom: 1.25rem;

  &.highlighted {
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 2px;
    padding: 0.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  p.title {
    margin-bottom: 0.2rem;
  }
}