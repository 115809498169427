$gray-7: #f9f9f9 !default;
$gray-6: #e5e5e5 !default;
$gray-5: #ccc !default;
$gray-4: #aaa !default;
$gray-3: #515151 !default;
$gray-2: #313131 !default;
$gray-1: #222 !default;

$red: #ac4142 !default;
$orange: #d28445 !default;
$yellow: #f4bf75 !default;
$green: #90a959 !default;
$blue: #53cfff !default;
$blue-faint: #53cfffdd !default;
$brown: #8f5536 !default;

$root-font-family: "Merriweather Sans", "Segoe UI", "Roboto", "Open Sans", Arial, sans-serif;
$root-font-size: 1rem !default;
$root-line-height: 1.5 !default;

$title-font-family: "IBM Plex Sans", "Fira Sans", "Fira Code", monospace;
$heading-font-family: "Noto Sans";

$body-color: $gray-6 !default;
$body-muted: $gray-4 !default;
$body-bg: rgba(255, 255, 255, 0.75) !default;
$link-color: $blue !default;
$heading-color: $gray-6 !default;

$border-color: $gray-2 !default;
$border-radius: 300px !default;
$padding-v: 1.5em !default;
$padding-h: 1.5em !default;
$heading-spacing: 1.5rem !default;
$section-spacing: 2rem !default;

$large-breakpoint: 49rem !default;

$box-shadow-size: 1px !default;
$box-shadow-opacity: 0.16 !default;
$default-box-shadow: $box-shadow-size
                     $box-shadow-size
                     $box-shadow-size
                     rgba(0, 0, 0, $box-shadow-opacity);

$code-font-family: "Fira Code", Menlo, Monaco, "Courier New", monospace !default;
$code-color: #bf616a !default;

$sidebar-bg-color: #183880 !default;
