aside {
  padding: 0;
  margin-bottom: 1rem;
  line-height: 1.4;
  background-color: #eee;
  background: repeating-linear-gradient(-60deg,
    #fff8,
    #fff8 10px,
    #fff7 10px,
    #fff7 12px
  );
  border-radius: .25rem;
  color: black;
  overflow: hidden;

  .inner {
    padding: 0.75rem 2rem;
    border: 0;
    border-left: 5px solid #38f;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 50%, transparent 100%);

    .title {
      display: block;
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 0.25rem;
    }

    .body {
      margin: 0;
    }
  }

  &.green {
    background-color: mix(white, $green, 70%);
    background: repeating-linear-gradient(
      -60deg,
      mix(white, $green, 80%),
      mix(white, $green, 80%) 10px,
      mix(white, $green, 60%) 10px,
      mix(white, $green, 60%) 12px
    );
    .inner { border-left: 3px solid $green; }
  }

  &.yellow {
    background-color: mix(white, $yellow, 65%);
    background: repeating-linear-gradient(
      -60deg,
      mix(white, $yellow, 75%),
      mix(white, $yellow, 75%) 10px,
      mix(white, $yellow, 40%) 10px,
      mix(white, $yellow, 40%) 12px
    );
    .inner { border-left: 3px solid $yellow; }
  }

  &.blue {
    background-color: mix(white, $blue, 75%);
    background: repeating-linear-gradient(
      -60deg,
      mix(white, $blue, 80%),
      mix(white, $blue, 80%) 10px,
      mix(white, $blue, 60%) 10px,
      mix(white, $blue, 60%) 12px
    );
    .inner { border-left: 3px solid $blue; }
  }

  &.red {
    background-color: mix(white, $red, 75%);
    background: repeating-linear-gradient(
      -60deg,
      mix(white, $red, 80%),
      mix(white, $red, 80%) 10px,
      mix(white, $red, 60%) 10px,
      mix(white, $red, 60%) 12px
    );
    .inner { border-left: 3px solid $red; }
  }
}