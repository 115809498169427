// Code
//
// Inline and block-level code snippets

code,
pre {
  font-family: $code-font-family;
}

code {
  padding: .25em .5em;
  font-size: 85%;
  color: $code-color;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 3px;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
}

pre code {
  padding: 0;
  font-size: 100%;
  color: inherit;
  background-color: transparent;
}

// Pygments/Rouge via Jekyll
.highlight {
  padding: 1rem;
  margin-bottom: 1rem;
  font-size: .8rem;
  line-height: 1.4;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: .25rem;

  pre {
    margin-bottom: 0;
    overflow-x: auto;
  }

  .lineno {
    display: inline-block; // Ensures the null space also isn't selectable
    padding-right: .75rem;
    padding-left: .25rem;
    color: #999;
    // Make sure numbers aren't selectable
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }
}

pre {
  max-width: 100%;
  overflow-x: auto;
}

// Gist via GitHub Pages
// .gist .gist-file {
//   font-family: Menlo, Monaco, "Courier New", monospace !important;
// }
// .gist .markdown-body {
//   padding: 15px;
// }
// .gist pre {
//   padding: 0;
//   background-color: transparent;
// }
// .gist .gist-file .gist-data {
//   font-size: .8rem !important;
//   line-height: 1.4;
// }
// .gist code {
//   padding: 0;
//   color: inherit;
//   background-color: transparent;
//   border-radius: 0;
// }

.highlight .hll { background-color: #ffc; }

.highlight .c   { color: #ababab; font-style: italic } /* Comment */
.highlight .cm  { color: #09f;    font-style: italic } /* Comment.Multiline */
.highlight .cp  { color: #cd2828; font-style: bold } /* Comment.Preproc */
.highlight .c1  { color: #999; } /* Comment.Single */
.highlight .cs  { color: #e50808; font-weight: bold; background-color: #520000 } /* Comment.Special */

.highlight .err { color: #a61717; background-color: #e3d2d2 } /* Error */

.highlight .k   { color: #6ebf26; font-weight: bold   } /* Keyword */
.highlight .kc  { color: #069;                        } /* Keyword.Constant */
.highlight .kd  { color: #069;                        } /* Keyword.Declaration */
.highlight .kn  { color: #069;                        } /* Keyword.Namespace */
.highlight .kp  { color: #069;    font-weight: normal } /* Keyword.Pseudo */
.highlight .kr  { color: #069;                        } /* Keyword.Reserved */
.highlight .kt  { color: #078;                        } /* Keyword.Type */

.highlight .o   { color: #555                         } /* Operator */
.highlight .ow  { color: #6ebf26; font-weight: bold   } /* Operator.Word */

.highlight .gd  { background-color: #d22323; border: 1px solid #c00 } /* Generic.Deleted */
.highlight .ge  { font-style: italic                } /* Generic.Emph */
.highlight .gs  { font-weight: bold                 } /* Generic.Strong */
.highlight .gr  { color: #d22323                    } /* Generic.Error */
.highlight .gh  { color: #ffffff; font-weight: bold } /* Generic.Heading */
.highlight .gu  { color: #ffffff; text-decoration: underline } /* Generic.Subheading */
.highlight .gi  { background-color: #589819; border: 1px solid #0c0 } /* Generic.Inserted */
.highlight .go  { color: #cccccc                    } /* Generic.Output */
.highlight .gp  { color: #aaaaaa                    } /* Generic.Prompt */
.highlight .gt  { color: #d22323                    } /* Generic.Traceback */

.highlight .m   { color: #f60                     } /* Literal.Number */
.highlight .s   { color: #ed9d13                  } /* Literal.String */
.highlight .mf  { color: #51b2fd                  } /* Literal.Number.Float */
.highlight .mh  { color: #51b2fd                  } /* Literal.Number.Hex */
.highlight .mi  { color: #51b2fd                  } /* Literal.Number.Integer */
.highlight .mo  { color: #51b2fd                  } /* Literal.Number.Oct */
.highlight .sb  { color: #c30                     } /* Literal.String.Backtick */
.highlight .sc  { color: #c30                     } /* Literal.String.Char */
.highlight .sd  { color: #c30; font-style: italic } /* Literal.String.Doc */
.highlight .s2  { color: #c30                     } /* Literal.String.Double */
.highlight .se  { color: #c30                     } /* Literal.String.Escape */
.highlight .sh  { color: #c30                     } /* Literal.String.Heredoc */
.highlight .si  { color: #a00                     } /* Literal.String.Interpol */
.highlight .sx  { color: #ffa500                  } /* Literal.String.Other */
.highlight .sr  { color: #3aa                     } /* Literal.String.Regex */
.highlight .s1  { color: #c30                     } /* Literal.String.Single */
.highlight .ss  { color: #fc3                     } /* Literal.String.Symbol */
.highlight .il  { color: #f60                     } /* Literal.Number.Integer.Long */

.highlight .na  { color: #bbbbbb } /* Name.Attribute */
.highlight .nb  { color: #2fbccd } /* Name.Builtin */
.highlight .nc  { color: #71adff } /* Name.Class */
.highlight .no  { color: #40ffff } /* Name.Constant */
.highlight .nd  { color: #ffa500 } /* Name.Decorator */
.highlight .ni  { color: #999    } /* Name.Entity */
.highlight .ne  { color: #bbbbbb } /* Name.Exception */
.highlight .nf  { color: #71adff } /* Name.Function */
.highlight .nl  { color: #99f    } /* Name.Label */
.highlight .nn  { color: #71adff; text-decoration: underline } /* Name.Namespace */
.highlight .nt  { color: #6ebf26; font-weight: bold } /* Name.Tag */
.highlight .nv  { color: #40ffff } /* Name.Variable */
.highlight .bp  { color: #366    } /* Name.Builtin.Pseudo */
.highlight .vc  { color: #033    } /* Name.Variable.Class */
.highlight .vg  { color: #033    } /* Name.Variable.Global */
.highlight .vi  { color: #033    } /* Name.Variable.Instance */

.highlight .w   { color: #bbb } /* Text.Whitespace */

.css .o,
.css .o + .nt,
.css .nt + .nt { color: #999; }
