/*
  Pagination

  Super lightweight (HTML-wise) blog pagination. Should only be visible
  when there is navigation available -- single buttons at top or bottom
  of each page.
*/

.pagination {
  color: $gray-3;
  text-align: center;

  > a {
    display: inline-block;
    padding: $padding-v $padding-h;
    width: 60%;
    max-width: 18rem;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: $default-box-shadow;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}

// Bottom -> margin-top;
* + .pagination {
  margin-top: $section-spacing;
}

// Push above header if newer on mobile
.content .pagination:first-child {
  margin-top: -$section-spacing * 2;
}

// Make room for larger header by extending margin below title
.index #sidebar {
  padding-bottom: calc(#{$section-spacing} + #{$padding-v});
}

// But not on page1
.home.index #sidebar {
  padding-bottom: $section-spacing;
}

// Undo for larger screens
@media (min-width: $large-breakpoint) {
  .pagination > a {
    box-shadow: none;

    &:hover { box-shadow: $default-box-shadow; }
  }

  .content .pagination:first-child {
    margin-top: 0;

    + * {
      padding-top: $section-spacing;
      border-top: 1px solid $border-color;
      margin-top: $section-spacing;
    }
  }

  .index #sidebar {
    padding-bottom: $section-spacing;
  }
}